<template>
  <div class="tw-time-select mb-16 flex justify-center">
    <div class="flex items-end gap-8">
      <WebSelect
        v-model="activeHour"
        name="hour"
        class="!mb-0 !w-[81px] !min-w-[81px]"
        :placeholder="translate('generate.placeholder.hour', locale)"
        :options="hourOptions"
        :page-options="pageOptions"
        :label="translate('generate.label.hour', locale)"
        :label-style="labelStyle"
        :hide-label="hideLabels"
        hide-selected-option-check
      />
      <span class="translate-y-[-1px] leading-10 text-neutral-500">:</span>
      <WebSelect
        v-model="activeMinute"
        name="minute"
        class="!mb-0 !w-[81px] !min-w-[81px]"
        :placeholder="translate('generate.placeholder.minute', locale)"
        :options="minuteOptions"
        :page-options="pageOptions"
        :label="translate('generate.label.minute', locale)"
        :label-style="labelStyle"
        :hide-label="hideLabels"
        hide-selected-option-check
      />
      <WebSelect
        v-if="!is24"
        v-model="activeTimePeriod"
        name="period"
        class="!mb-0 !w-[81px] !min-w-[81px]"
        :placeholder="translate('generate.placeholder.timePeriod', locale)"
        :options="[
          { label: translate('generate.label.AM', locale), value: 'AM' },
          { label: translate('generate.label.PM', locale), value: 'PM' }
        ]"
        :page-options="pageOptions"
        :label-style="labelStyle"
        :hide-label="hideLabels"
        hide-selected-option-check
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type PropType, computed, ref } from 'vue';
import WebSelect from '@shared/components/select/index.vue';
import { parseTime, convert12HourTo24Hour, fillHourOptions, fillMinuteOptions } from '../utils';
import { useTranslate } from '@shared/composable/useTranslate';
import type { PageOptions } from '@shared/types/model';

const { translate } = useTranslate();

const props = defineProps({
  modelValue: { type: String, default: '' },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  locale: { type: String, default: '' },
  is24: { type: Boolean, default: true },
  labelStyle: { type: Object, default: undefined },
  hideLabels: { type: Boolean, default: false }
});

const emit = defineEmits(['update:modelValue']);

const { twelveHour_hour, twentyFourHour_hour, minute, period } = parseTime(props.modelValue);

const selectedHour = ref(props.is24 ? twentyFourHour_hour || '' : twelveHour_hour || '');
const selectedMinute = ref(minute || '');
const selectedPeriod = ref(period || '');
const hourOptions = computed(() => fillHourOptions(props.is24));
const minuteOptions = fillMinuteOptions();

const activeHour = computed({
  get() {
    const { twelveHour_hour, twentyFourHour_hour } = parseTime(props.modelValue);
    const hour = props.is24 ? twentyFourHour_hour : twelveHour_hour;
    return hour || selectedHour.value;
  },
  set(value) {
    selectedHour.value = value;
    updateModelValue();
  }
});

const activeMinute = computed({
  get() {
    const { minute } = parseTime(props.modelValue);
    return minute || selectedMinute.value;
  },
  set(value) {
    selectedMinute.value = value;
    updateModelValue();
  }
});

const activeTimePeriod = computed({
  get() {
    const { period } = parseTime(props.modelValue);
    return period || selectedPeriod.value;
  },
  set(value) {
    selectedPeriod.value = value;
    updateModelValue();
  }
});

function updateModelValue() {
  let fullTime = `${selectedHour.value}:${selectedMinute.value}`;
  if (!selectedHour.value || !selectedMinute.value) return emit('update:modelValue', '');
  if (!props.is24) {
    fullTime = convert12HourTo24Hour(fullTime, selectedPeriod.value || '');
  }
  return emit('update:modelValue', fullTime);
}
</script>

<style lang="postcss" scoped>
:deep(.tw-selections__text) {
  @apply text-neutral-900;
}
</style>
